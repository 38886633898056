import type { Locale } from '@fxtr/i18n';
import config from 'config';

export enum KameleoonGoals {
  ChooseCustomerCnD = 'ChooseCustomerCnD',
  ChooseFixterCnD = 'ChooseFixterCnD',
  ForcedCustomerCnD = 'ForcedCustomerCnD',
  EWOProposedBasketSize = 'EWOProposedBasketSize',
  EWOProposedAcceptanceRate = 'EWOProposedAcceptanceRate',
  EWOPaymentBasketSize = 'EWOPaymentBasketSize',
  EWOPaymentAcceptanceRate = 'EWOPaymentAcceptanceRate',
}

/**
 * get Kameleoon custom goals by locale
 */

export const getABTestGoal = (goalName: string, locale: Locale): number | undefined =>
  config.get(`public.kameleoon.goals.${goalName}.${locale}`);
